<!--荣誉殿堂(个人介绍)-->
<template>
  <div>
    <el-row>
      <el-button style="float: right" icon="el-icon-arrow-left" type="warning" round @click="returnBtn">{{ $t('sysMailQueue.return') }}</el-button>
    </el-row>
    <div class="details">
      <div class="details-letf">
        <!--大图-->
        <div
            :class="{ 'image-border': imageBorder }"
            class="letf-head"
            @mouseenter="imageBorder = true"
            @mouseleave="imageBorder = false"
        >
          <el-image
              :preview-src-list="iamgeList"
              :src="imgsrc"
              fit="contain"
              style="width: 100%; height: 100%"
          >
          </el-image>
        </div>
        <!--小图展示-->
        <div class="letf-center-img" v-for="(img, index) in iamgeList" :key="index">
          <el-image
              :preview-src-list="iamgeList"
              :src="img"
              fit="contain"
              style="width: 100%; height: 100%"
          >
          </el-image>
        </div>
        <!-- 防止塌陷 -->
        <div style="clear: both"></div>
      </div>
      <div class="details-right">
        <h2 style="text-align: left; color: #e6a23c; word-break: break-all; margin-top: 0;">{{ memberName }}</h2>
        <el-card>
        <!-- 个人介绍-->
          <p class="right-one">{{ $t('个人介绍') }}</p>
          <p class="right-two" />
          <p style="text-align: left" v-html="profile" />
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
  import { viewById } from '@/api/ic/honourList'
  export default {
    name: "HonorPersonal",
    data() {
      return {
        profile: '',
        imgsrc: '',
        iamgeList: [],
        personalId: this.$route.params.id,
        memberName: '',
        imageBorder: false
      }
    },
    created() {
      if (this.personalId) {
        this.getInfo()
      } else if (!this.personalId){
        this.$router.push('/honorandcommendation')
      }
    },
    methods: {
      getInfo() {
        // 开始加载loading
        let loading = this.$loading({
          lock: true, //lock的修改符--默认是false
          text: this.$t('app.loading') + '...', //显示在加载图标下方的加载文案
          background: "rgba(0,0,0,0.4)", //遮罩层颜色
          spinner: "el-icon-loading", //自定义加载图标类名
        });
        viewById({ 'id': this.personalId}).then(res => {
          this.iamgeList = res.data.urls
          this.imgsrc = this.iamgeList[0]
          this.profile = res.data.profile
          this.memberName = res.data.memberName
          loading.close()
        }).catch(() => {
          loading.close()
        })
      },
      returnBtn() {
        this.$router.go(-1)
      }
    }
  }
</script>

<style lang="scss" scoped>
.details {
  width: 100%;
  display: flex;
  overflow: hidden;
  box-sizing: border-box;
  margin-top: 10px;
}
.details-letf {
  flex: 1;
}
.image-border {
  border: 1px solid #337ab7;
}
.letf-head {
  padding: 2px 5px;
  height: 260px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 10px 20px rgb(153 157 163 / 5%),
  0 5px 10px rgb(153 157 163 / 5%);
  cursor: pointer;
  margin-bottom: 5px;
}
.letf-center-img {
  float: left;
  margin-right: 10px;
  width: 100px;
  height: 100px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 10px 20px rgb(153 157 163 / 5%),
  0 5px 10px rgb(153 157 163 / 5%);
}
.details-right {
  width: 450px;
  margin: 0 10px;
  box-sizing: border-box;
}
.right-one {
  background-color: #fff;
  border: 1px solid #ddd;
  width: 80px;
  padding: 10px;
  color: #ff5f00;
  border-bottom-color: transparent;
  margin: 0;
  border-radius: 5%;
}
.right-two {
  flex: 1;
  margin: 0;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}
@media screen and (max-width: 768px) {
  .details {
    width: 100%;
    display: block;
    overflow: hidden;
    box-sizing: border-box;
    margin-top: 10px;
  }
  .details-right {
    width: 100%;
    margin: 0;
    box-sizing: border-box;
  }
}

</style>
